import React from "react";

function CaseStudiesSection() {
  // Sample data for the case studies
  const caseStudies = [
    {
      id: 1,
      title: "GudGum's Offline Expansion with RetailWise",
      description: [
        "GudGum, a premium confectionery brand featured on Shark Tank, sought to expand its offline presence. RetailWise conducted a market study, organised sampling events, and developed a GTM strategy based on consumer feedback to identify optimal sales channels. Pharmacies and cafes emerged as ideal fits for the brand. Within 2 month, stores started showing 70% repeat orders.",
      ],
    },
    {
      id: 2,
      title: "Lorum ipsum intega styusm fo hyight",
      description: [
        "A promising feminine hygiene, having tremendous repeat online planned to go offline in Bangalore.",
        "In a matter of 2 month, brand is placed in 100+ stores including large format hypermarkets, supermarkets, premium Pharma stores.",
        "For in-store marketing, promoters were also deployed leading to brand awareness and capturing market share. All this happened without any fixed cost commitment from brand.",
      ],
    },
    {
      id: 3,
      title: "Lorum ipsum intega styusm fo hyight",
      description:
        "Prominent inclusion drink brand from Kerela wanted to explore Bangalore and RetailWise was able to conduct market research (competitor's benchmarking, store profile) and build GTM strategy. With dedicated effort, brand was placed in relevant stores and started seeing repeat orders within two week of placement. In next phase now, brand is expanding to larger store base and better offerings.",
    },
  ];

  return (
    <section className="py-6 md:py-12 bg-white font-coustard">
      <div className="container mx-auto md:px-4">
        {/* Section Title */}
        <h2 className="text-2xl md:text-5xl font-medium text-gray-800 text-left md:pb-4 mb-4 md:mb-8 border-b">
          Case <span className="text-[#138784]">Studies</span>
        </h2>

        {/* Case Study Cards Container with Horizontal Scroll on Mobile */}
        <div className="mt-4 md:mt-8 overflow-x-auto md:overflow-visible">
          <div className="flex lg:grid lg:grid-cols-3 gap-4 md:gap-6">
            {caseStudies.map((study) => (
              <CaseStudyCard
                key={study.id}
                title={study.title}
                description={study.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function CaseStudyCard({ title, description, buttonLabel }) {
  return (
    <div className="bg-[#F6FAFA] rounded-xl shadow p-3 md:p-5 border border-gray-200 min-w-[80%] sm:min-w-[60%] md:min-w-0 font-helvetica">
      <h3 className="text-[#95A4A4] text-sm md:text-base font-medium pb-1 border-b border-gray-300">
        Case study
      </h3>

      {/**<h4 className="text-base md:text-lg lg:text-2xl font-semibold text-gray-900 mt-3 md:mb-2">
        {title}
      </h4>**/}
      <p className="text-[#138784] text-xl md:text-2xl lg:text-3xl leading-none md:mb-2">
        <svg
          className="hidden lg:block mt-4"
          width="53"
          height="36"
          viewBox="0 0 53 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3125 2.4375C19.3125 3.52083 18.2708 5.52083 16.1875 8.4375C14.1458 11.3125 13.125 12.8333 13.125 13C13.125 13.4167 13.5 13.7917 14.25 14.125C15 14.4583 15.9167 14.8333 17 15.25C18.0833 15.6667 19.1667 16.2083 20.25 16.875C21.375 17.5 22.3125 18.4792 23.0625 19.8125C23.8125 21.1458 24.1875 22.7292 24.1875 24.5625C24.1875 27.7292 23.0833 30.3125 20.875 32.3125C18.7083 34.3125 15.8958 35.3125 12.4375 35.3125C9.10417 35.3125 6.27083 34.2292 3.9375 32.0625C1.64583 29.8542 0.5 27.1667 0.5 24C0.5 20.375 1.22917 16.75 2.6875 13.125C4.1875 9.45833 6.16667 6.4375 8.625 4.0625C11.125 1.6875 13.6875 0.5 16.3125 0.5C18.3125 0.5 19.3125 1.14583 19.3125 2.4375ZM48.125 2.4375C48.125 3.52083 47.0833 5.52083 45 8.4375C42.9583 11.3125 41.9375 12.8333 41.9375 13C41.9375 13.375 42.2083 13.7083 42.75 14C43.2917 14.2917 43.9792 14.5625 44.8125 14.8125C45.6458 15.0625 46.5208 15.4375 47.4375 15.9375C48.3958 16.4375 49.2917 17.0208 50.125 17.6875C50.9583 18.3125 51.6458 19.2292 52.1875 20.4375C52.7292 21.6458 53 23.0208 53 24.5625C53 27.7292 51.8958 30.3125 49.6875 32.3125C47.5208 34.3125 44.7083 35.3125 41.25 35.3125C37.9167 35.3125 35.0833 34.2292 32.75 32.0625C30.4583 29.8542 29.3125 27.1667 29.3125 24C29.3125 20.375 30.0417 16.75 31.5 13.125C33 9.45833 34.9792 6.4375 37.4375 4.0625C39.9375 1.6875 42.5 0.5 45.125 0.5C47.125 0.5 48.125 1.14583 48.125 2.4375Z"
            fill="#7FA3A2"
            fill-opacity="0.39"
          />
        </svg>
        <svg
          className="block lg:hidden"
          width="30"
          height="36"
          viewBox="0 0 53 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3125 2.4375C19.3125 3.52083 18.2708 5.52083 16.1875 8.4375C14.1458 11.3125 13.125 12.8333 13.125 13C13.125 13.4167 13.5 13.7917 14.25 14.125C15 14.4583 15.9167 14.8333 17 15.25C18.0833 15.6667 19.1667 16.2083 20.25 16.875C21.375 17.5 22.3125 18.4792 23.0625 19.8125C23.8125 21.1458 24.1875 22.7292 24.1875 24.5625C24.1875 27.7292 23.0833 30.3125 20.875 32.3125C18.7083 34.3125 15.8958 35.3125 12.4375 35.3125C9.10417 35.3125 6.27083 34.2292 3.9375 32.0625C1.64583 29.8542 0.5 27.1667 0.5 24C0.5 20.375 1.22917 16.75 2.6875 13.125C4.1875 9.45833 6.16667 6.4375 8.625 4.0625C11.125 1.6875 13.6875 0.5 16.3125 0.5C18.3125 0.5 19.3125 1.14583 19.3125 2.4375ZM48.125 2.4375C48.125 3.52083 47.0833 5.52083 45 8.4375C42.9583 11.3125 41.9375 12.8333 41.9375 13C41.9375 13.375 42.2083 13.7083 42.75 14C43.2917 14.2917 43.9792 14.5625 44.8125 14.8125C45.6458 15.0625 46.5208 15.4375 47.4375 15.9375C48.3958 16.4375 49.2917 17.0208 50.125 17.6875C50.9583 18.3125 51.6458 19.2292 52.1875 20.4375C52.7292 21.6458 53 23.0208 53 24.5625C53 27.7292 51.8958 30.3125 49.6875 32.3125C47.5208 34.3125 44.7083 35.3125 41.25 35.3125C37.9167 35.3125 35.0833 34.2292 32.75 32.0625C30.4583 29.8542 29.3125 27.1667 29.3125 24C29.3125 20.375 30.0417 16.75 31.5 13.125C33 9.45833 34.9792 6.4375 37.4375 4.0625C39.9375 1.6875 42.5 0.5 45.125 0.5C47.125 0.5 48.125 1.14583 48.125 2.4375Z"
            fill="#7FA3A2"
            fill-opacity="0.39"
          />
        </svg>
      </p>
      <p className="text-gray-700 text-xs md:text-sm lg:text-base mb-4">
        {description}
      </p>
    </div>
  );
}

export default CaseStudiesSection;
