import React from "react";

function DeadlinksPage() {
  return (
    <div
      className="min-h-screen flex flex-col justify-center items-center bg-cover bg-center text-[#138784]"
      style={{
        backgroundImage: "url('/assets/images/404.jpg')",
      }}
    >
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-[#F6F5F5]/80"></div>

      {/* Content */}
      <div className="relative z-10 text-center">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <p className="text-2xl font-medium mb-6">
          Oops! The page you're looking for doesn't exist.
        </p>
        <a
          href="/"
          className="px-6 py-3 bg-[#138784] text-white rounded-lg shadow-md hover:shadow-lg transition duration-300 text-lg font-medium"
        >
          Go to Home
        </a>
      </div>
    </div>
  );
}

export default DeadlinksPage;
