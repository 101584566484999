import React, { useState } from "react";

function Modal({ onClose }) {
  const [showThankYou, setShowThankYou] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    if (!name.trim()) {
      setErrorMessage("Please enter a valid name.");
      return false;
    }
    const phonePattern = /^[0-9]{10}$/; // Adjust as needed
    if (!phonePattern.test(phone)) {
      setErrorMessage("Please enter a valid 10-digit contact number.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  // Handle form submission for Option #1
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop if validation fails

    setLoading(true); // Start loading
    try {
      const message = `New inquiry from RetailWise:\nName: ${name}\nContact Number: ${phone}`;
      const response = await fetch("https://api.retailwise.in/api/v1/send-to-slack", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: message }),
      });

      if (response.ok) {
        setShowThankYou(true);
      } else {
        setErrorMessage("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Handle WhatsApp chat for Option #2
  const handleWhatsAppChat = () => {
    const message = encodeURIComponent("Hi, I am interested to know more about your services.");
    const whatsappUrl = `https://wa.me/+919916101296?text=${message}`;
    window.open(whatsappUrl, "_blank");
    onClose(); // Close the modal
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-hidden">
      <div className="w-[90%] max-w-lg rounded-lg p-8 relative shadow-lg bg-[#F6F5F5]">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800 text-3xl"
        >
          &times;
        </button>

        {showThankYou ? (
          <h2 className="text-xl text-center text-[#138784]">
            Thank you! We will get back to you shortly.
          </h2>
        ) : (
          <>
            <h2 className="text-lg md:text-2xl text-[#414141] font-normal mb-4 border-b border-[#E8E8E8] pb-2">
              Please enter your details, and{" "}
              <span className="text-[#138784] font-semibold">RetailWise</span> will be in
              touch with you shortly.
            </h2>

            {/* Option #1: Form Submission */}
            <form className="space-y-4" onSubmit={handleFormSubmit}>
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className={`w-full rounded-md border p-2 mt-1 ${
                    errorMessage.includes("name") ? "border-red-500" : "border-gray-300"
                  }`}
                />
              </div>
              <div>
                <input
                  type="tel"
                  placeholder="Contact number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  className={`w-full rounded-md border p-2 mt-1 ${
                    errorMessage.includes("contact number") ? "border-red-500" : "border-gray-300"
                  }`}
                />
              </div>
              <button
                type="submit"
                className="w-full bg-[#138784] hover:bg-teal-600 text-white py-2 rounded-md font-semibold transition flex items-center justify-center"
                disabled={loading}
              >
                {loading ? (
                  <>
                    Submitting
                    <span className="ml-2 animate-spin border-2 border-white border-t-transparent rounded-full h-5 w-5"></span>
                  </>
                ) : (
                  <>
                    Submit{" "}
                    <svg
                      className="ml-2"
                      width="30"
                      height="24"
                      viewBox="0 0 52 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM51.5607 13.0607C52.1464 12.4749 52.1464 11.5251 51.5607 10.9393L42.0147 1.3934C41.4289 0.807611 40.4792 0.807611 39.8934 1.3934C39.3076 1.97919 39.3076 2.92893 39.8934 3.51472L48.3787 12L39.8934 20.4853C39.3076 21.0711 39.3076 22.0208 39.8934 22.6066C40.4792 23.1924 41.4289 23.1924 42.0147 22.6066L51.5607 13.0607ZM2 13.5H50.5V10.5H2V13.5Z"
                        fill="#E5E5E5"
                      />
                    </svg>
                  </>
                )}
              </button>
            </form>

            {/* Error Message */}
            {errorMessage && (
              <p className="text-red-600 bg-red-100 border border-red-500 rounded-md text-center mt-3 p-2">
                {errorMessage}
              </p>
            )}

            <div className="text-center text-[#C4C4C4] mt-4">or</div>

            {/* Option #2: WhatsApp Chat */}
            <button
              onClick={handleWhatsAppChat}
              className="flex items-center text-md w-full justify-center mt-2 p-2 px-4 border rounded-3xl text-[#8A8A8A] bg-white font-medium mx-auto"
            >
              <img
                src="/assets/images/Hero/whatsapp_icon.svg"
                alt="WhatsApp"
                className="w-4 mr-2"
              />
              Connect with us on WhatsApp
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;
