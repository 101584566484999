import React, { useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";

function WhyCard({ icon, title, subtitle, description, className, index }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, margin: "-100px 0px" });

  useEffect(() => {
    console.log(`Card ${index} in view: `, isInView);
  }, [isInView, index]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, delay: index * 0.2 },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={variants}
      whileHover={{ scale: 1.05 }}
      className={`bg-[#F7F7F7] rounded-xl shadow-sm border border-why-border ${className} flex flex-col justify-between font-helvetica`}
      tabIndex={0}
    >
      {/* Main content of the card */}
      <div
        key={index}
        className={`rounded-lg p-2 pr-8 md:p-4 flex flex-col ${
          index % 2 === 0 ? "bg-[#F6FAFA]" : "bg-[#F7F7F7]"
        }`}
      >
        <h3 className="text-md md:text-xl xl:text-2xl font-semibold text-black mb-2 font-helvetica">
          {title}
        </h3>
        <p className="text-[#6A6B6B] text-sm md:text-md xl:text-xl">{description}</p>
      </div>

      {/* Icon container at the bottom */}
      <div className="flex justify-end m-4">
        <img src={icon} alt={`${title} icon`} className="" />
      </div>
    </motion.div>
  );
}

function WhyRetailWiseSection() {
  return (
    <section className="bg-white py-10 px-4 md:px-8 w-full overflow-hidden">
      <div className="relative container mx-auto md:px-4 flex flex-col w-full">
        <h2 className="relative text-2xl md:text-5xl font-medium text-gray-800 pb-4 lg:mb-8 text-left font-coustard border-b">
          Why <span className="text-[#138784]">RetailWise</span>
        </h2>
        <div className="hidden lg:grid grid-cols-4 grid-rows-1 gap-4 gap-black w-full h-full bg-[#F9F9F9] p-6 pb-0 overflow-hidden rounded-xl">
          {/* Static empty cards */}
          <div className="row-start-1 col-start-1">
            <div className="border bg-white rounded-xl w-full h-full relative -top-[350px] xl:-top-[350px] overflow-hidden mb-4"></div>
            <WhyCard
              title="Full Stack"
              description="Right from product pitching to store, planning offtake and distribution, we take care of all."
              icon="/assets/images/Why/stack-icon.svg"
              className="row-start-2 col-start-1 relative -top-[340px] xl:-top-[350px] col-span-1"
              index={0}
            />
          </div>
          <div className="row-start-1 col-start-3">
            <div className="border bg-white rounded-xl w-full h-full relative -top-[340px] xl:-top-[350px] overflow-hidden mb-4"></div>
            <WhyCard
              title="Quick To Market"
              description="Hypermarket, Supermarket, Pharma, Organic, Baby Stores - get product placed in a matter of few weeks."
              icon="/assets/images/Why/market-icon.svg"
              className="row-start-2 col-start-3 relative -top-[340px] xl:-top-[350px] col-span-1"
              index={2}
            />
          </div>
          <div className="row-start-1 col-start-2">
            <WhyCard
              title="Low Cost"
              description="No need to setup own team and have huge upfront cost, instead have a variable cost structure."
              icon="/assets/images/Why/cost-icon.svg"
              className="row-start-1 col-start-2 col-span-1 mb-4"
              index={1}
            />
            <div className="border bg-white rounded-xl rounded-b-none w-full h-24 relative overflow-hidden"></div>{" "}
          </div>
          <div className="row-start-1 col-start-4">
            {/* WhyCard Component */}
            <WhyCard
              title="Byte Sized"
              description="Not sure about offline response? Start pilot with a few stores only before expanding."
              icon="/assets/images/Why/byte-icon.svg"
              className="row-start-1 col-start-4 col-span-1 mb-4" // Ensure no margin below the card
              index={3}
            />
            {/* Static Background Card */}
            <div className="border bg-white rounded-xl rounded-b-none w-full h-full relative overflow-hidden"></div>{" "}
            {/* Remove margin-top */}
          </div>

          {/* Animated content cards */}
        </div>
        <div className="lg:hidden grid xs:grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-8">
          <WhyCard
            title="Full Stack"
            description="Right from product pitching to store, planning offtake and distribution, we take care of all"
            icon="/assets/images/Why/stack-icon.svg"
          />
          <WhyCard
            title="Low Cost"
            description="Otherwise would need whole team structure to be built, huge upfront cost"
            icon="/assets/images/Why/cost-icon.svg"
          />
          <WhyCard
            title="Quick To Market"
            description="We work with all top hypermarket, supermarket stores, in a matter of few weeks - product can be placed across all stores"
            icon="/assets/images/Why/market-icon.svg"
          />
          <WhyCard
            title="Byte Sized"
            description="Not sure about offline response? Low on budget? Start with a few stores only before expanding. Very much possible"
            icon="/assets/images/Why/byte-icon.svg"
          />
        </div>
      </div>
    </section>
  );
}

export default WhyRetailWiseSection;
